import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'

export default ({ data: { wordpress: { page } } }) => (
  <Layout>
    <article id={`page-${page.pageId}`} className="post-1 post type-post status-publish format-standard hentry category-uncategorized entry">
      <header className="entry-header">
        <h2 className="entry-title">
          {page.title}
        </h2>
      </header>
      <div className="entry-content" dangerouslySetInnerHTML={{ __html: page.content }} />
    </article>
  </Layout>
)

export const query = graphql`
  query($id: ID!) {
    wordpress {
      page(id: $id) {
        pageId
        title
        content
      }
    }
  }
`
